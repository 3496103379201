$(function() {
  $("form[name='subscription']").validate({
    errorElement : 'div',
    errorPlacement: function(error, element) {
      var placement = $(element).data('error');
      if (placement) {
        if (placement == "#roles-error") {
          $("#roles-error").html(error);
        } else {
          $(placement).append(error)
        }

      } else {
        error.insertAfter(element);
      }
    },
    rules: {
      "subscription[first_name]": "required",
      "subscription[last_name]": "required",
      "subscription[address]": "required",
      "subscription[postcode]": "required",
      "subscription[city]": "required",
      "subscription[country]": "required",
      "subscription[invoicing_country]": "required",
      "subscription[language_id]": "required",
      "subscription[contact_id]": "required",
      "roles": {
        require_from_group: [1, ".group"]
      },
      "subscription[invoicing_email]": { email: true },
      "code": {
        required: function () {
          return ($( "#subscription_contact_id option:selected" ).attr("data-use_code") === "true");
        }
      },
      "privacy": "required",
      "location": "required",
      "details": "required",
      "subscription[vat_number]": {
          required: function () {
              return ($("#subscription_company_name").val().length > 0);
          },
          pattern: /[a-zA-Z]{2}\d{8}/
      },
      "password": {
        minlength : 8
      },
      "password_confirmation": {
        minlength : 8,
        equalTo : "#password"
      },
      "subscription[user_email]": {
        email: true,
        required: true,
        remote: {
          url: "/checkemail",
          type: "post",
          data: {
            user_email: function() {
              return $( "#subscription_user_email" ).val();
            }
          }
        }
      }
    },
    messages: {
      "subscription[first_name]": "Please enter your firstname",
      "subscription[last_name]": "Please enter your lastname",
      "subscription[user_email]": { 
        email: "Please enter a valid email address",
        required: "Please enter a valid email address",
        remote: "Email address already in use"
      },
      "subscription[address]": "Please enter your street address",
      "subscription[postcode]": "Please enter your zip code",
      "subscription[city]": "Please enter your city",
      "subscription[country]": "Please enter your country",
      "subscription[language_id]": "Please choose a language",
      "subscription[invoicing_country]": "Please enter your country",
      "subscription[vat_number]": "Please enter a valid VAT number",
      "roles": "Please choose atleast one",
      "password": "Please choose a password",
      "password_confirmation": "Password does not match",
      "privacy": "Please accept",
      "location": "Please accept",
      "details": "Please accept"
    },
    submitHandler: function() {
      if($("#subscription_vat_number").hasClass('not-valid')) {
        $('html, body').animate({scrollTop: $("#subscription_vat_number").offset().top -100 }, 'slow');
        return false
      } else {
        validateBackend();
      }
    }
  });
});

$.validator.addMethod('group', function(value, elem) {
  return $('.roles-form').find('.group:checked').length > 0;
}, 'Please check at least one box in this group.');

function validateBackend() {
  $.ajax({
    type: "POST",
    url: '/validate_account',
    data: $( "#subscription-form" ).serializeArray(),
    dataType: 'json'
  }).done( function( data ) {
    console.log("resp: " + data);
    if (data.success === true) {
      $( "#subscription_id" ).val(data.id).change(

          $( "#subscription-form" )[0].submit()
      );
    } else {
      Object.keys(data.message).forEach(function(key) {
        $('#' + key + "_error").text(data.message[key]).addClass("error");
        console.log('Key : ' + key + ', Value : ' + data.message[key])
      })
      var first_error = Object.keys(data.message)[0];
      $('html, body').animate({scrollTop: $("#" + first_error + "_error").offset().top -100 }, 'slow');
      $( "#card_payment" ).prop('checked', false);
    }
  });
}